<script setup lang="ts">
import { showToast } from 'vant'
import { map } from 'rxjs/operators'
import VueQrcode from 'vue-qrcode'
import { PersonalApi } from '~/net/apis/personal_api'
import share_bottom_new from '~/assets/image/promote_share/share_bottom_new.png'
import { handleCopy } from '~/utils/copy'

const router = useRouter()
const onClickLeft = () => history.back()
function goShareRecord() {
  router.push('/promote-share-record')
}
function save() {
  showToast('保存成功')
}
const shareInfo = useObservable(from(PersonalApi.shareInfo()).pipe(map(({ data }) => data)))
console.log('shareInfo', shareInfo)

const marquees = ref<string>('')

function getMarquees(len: number): void {
  const _userChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  const _availableChars = '%s 用户刚刚获得了一张VIP卡'
  const _random = () => Math.floor(Math.random() * _userChars.length)
  const mar = []
  for (let i = 0; i < len; i++) {
    const result = Array.from({ length: 5 }, () => _userChars.charAt(_random())).join('')
    mar.push(sprintf(_availableChars, [`${result}*** `]))
  }
  marquees.value = mar.join(', ')
}

// 模拟 sprintf 函数，可以根据实际情况引入实际的 sprintf 库
function sprintf(format: string, args: any[]): string {
  return format.replace(/%s/g, () => args.shift())
}

getMarquees(10)
</script>

<template>
  <div h-screen class="share">
    <van-nav-bar title="分享推广" left-arrow @click-left="onClickLeft" />
    <van-notice-bar scrollable :text="marquees" />
    <div flex flex-col items-center justify-center class="cont-h">
      <div class="bg1" h-51 w-51 flex items-center justify-center>
        <VueQrcode :value="shareInfo?.sharePicUrl || ''" h-48 w-48 />
      </div>
      <div class="share1">
        我的累积分享人数：{{ shareInfo?.shareTotal || 0 }}
        <img :src="share_bottom_new" @click="goShareRecord" />
      </div>
      <div mt-4 flex justify-center>
        <div class="bg4" round mr-3 h-9 w-31.5 text-center leading-9 ct-ffffff @click="save">保存图片</div>
        <div class="bg4" round h-9 w-31.5 text-center leading-9 ct-ffffff @click="handleCopy(shareInfo?.sharePicUrl || '')">
          复制分享链接
        </div>
      </div>
    </div>
    <div fixed bottom-0 h-27 w-full>
      <div h-6.5 text-center text-xs leading-6.5 ct-ffffff class="bg2">分享专属链接：{{ shareInfo?.sharePicUrl || '' }}</div>
      <div class="bg3" p-2 text-xs ct-ffffff>
        <p>推广说明:</p>
        <p>1.好友通过您的二维码或推广链接下载JAV App后，并启动即计为推广成功。</p>
        <p>2.活动最终解释权归JAV所有，有任何疑问请联系客服。</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: transparent;
}
:deep(.van-nav-bar__title) {
  color: #333333;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-nav-bar) {
  background: transparent;
}
.share {
  background: url('~/assets/image/promote_share/banner@2x_new.png') no-repeat;
  background-position: center center;
  background-size: cover;
}
:deep(.van-notice-bar) {
  background: #0000001a;
  height: 20px;
  line-height: 20px;
  color: #ffffff;
}
.share1 {
  background: url('~/assets/image/promote_share/share_top_new.png') no-repeat;
  background-position: center center;
  background-size: cover;
  height: 142px;
  width: 269px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  margin-top: 10px;
}
.bg1 {
  background: linear-gradient(136.99deg, rgba(145, 38, 205, 0.85) 3.04%, rgba(81, 15, 139, 0.85) 99.27%);
}
.bg4 {
  background: linear-gradient(95.57deg, #fd9403 1.92%, #fd4b03 96.87%);
  border-radius: 10px;
  padding:0 6px;
}
.bg2 {
  background: #0000004d;
}
.bg3 {
  background: #4c3568de;
}
.cont-h {
  height: calc(100% - 66px);
}
</style>
